export const downloadTicket = async (setDownloadingTicket, uuid = '', pendingPays = []) => {
    try {
      setDownloadingTicket(true)

      const request = await fetch(`${sessionStorage.getItem('localhost')}/api/v2/cart/tickets?uuid=${uuid}&pendingPays=${JSON.stringify(pendingPays)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          'Access-Control-Allow-Origin': '*'
        }
      })

      if (request.ok === true) {
        const blob = await request.blob()
        const url = URL.createObjectURL(blob)

        const hasIframe = document.querySelector('iframe')
        if (hasIframe) hasIframe.parentNode.removeChild(hasIframe)

        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.src = url

        document.body.appendChild(iframe)

        iframe.onload = () => {
          iframe.focus()
          iframe.contentWindow.focus()
          iframe.contentWindow.print()
        }

        return
      }

      throw new Error('Ocurrió un error con la petición')
    } catch (error) {
      console.error(error.message)
    } finally {
      setDownloadingTicket(false)
    }
}